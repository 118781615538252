@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~handsontable/dist/handsontable.full.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
// Global component style
// variable Declaration Starts
$black: #000000;
$fontcolor: #333333;
$fontcolor-1: #4a4a4a;
$bg-gry: #f9f9f9;
$bg-orange: #eaaa00;
$font-body: 16px;
$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-15: 15px;
$font-18: 18px;
$fontfamily: Calibri;
$bg-green: #43b02a;
$bg-blue: #005eb8;
$bg-header: #00338d;
$table-header: #ebebeb;
$bg-border: #f9f9f9;
$default-grey: #747474;
$ltr-value: ltr;
$rtl-value: rtl;
$ltr-direction: left;
$rtl-direction: right;
$radius: 6px;
$delay: 0.2s;
$no-color: transparent;
$bg-danger: #bc204b;
$panel-head: #ebebeb;
$bg-border-dark: #979797;
$bg-body: #f9f9f9;
$bg-selected: #e7eff8;
$bg-disable: #c6c6c6;
$input-height: 30px;
$paddMargin: 0px;

// variable Declaration Ends

*:not(.fa) {
  font-family: $fontfamily  !important;
}

body {
  direction: $ltr-value;
  text-align: $ltr-direction;
  font-family: $fontfamily;
  font-size: $font-body;
  color: $fontcolor;
  background-color: $bg-body;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;

  :focus {
    outline: 0;
  }

}

.breadcrumb-item+.breadcrumb-item::before {
  font-family: FontAwesome;
  content: "\f054" !important;
  color: #333;
  font-size: 11px;
  float: none;
}

.breadcrumb-container ul {
  padding: $paddMargin;
  margin: $paddMargin;

  li {
    list-style: none;
    padding-top: 7px;
    display: inline-block;

    a {
      color: #005eb8 !important;
      font-size: 16px;
      padding: 0;
      display: inline-block;
      text-decoration: none;
      font-family: Calibri;
      font-weight: 400;
      position: relative;
      cursor: pointer !important;

      i {
        font-size: 17px;
      }
    }
  }
}

.breadcrumb-container {
  position: relative;

}


.rectangle-2.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: inline-block;
  min-width: 40px;
  background-color: #333;
  padding: 0;
  margin: 0;
  vertical-align: top;
  overflow-y: inherit;
  max-width: 40px;
}

.mainContainer {
  float: left;
  width: 100%;
  margin: 15px 15px 0;
  border: 1px solid #efefef;
  background-color: #fff;
  width: calc(100% - 30px);
  min-height: calc(100vh - 70px);
  position: relative;
  box-shadow: 0 1px 2px 0 #9b9b9b;
  padding: 10px 10px 0;
}

input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

hot-table {
  position: relative;
  background: transparent;
  width: 100%;
  float: left;

  .handsontable td,
  .handsontable th {
    background-color: #fff !important;
    line-height: 35px !important;
    font-family: Calibri;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: .44px;
  }

  .handsontable th {
    color: #222;
    text-align: center;
    white-space: nowrap;
  }

  .handsontable thead th .relative {
    padding: 2px 10px;
    text-align: left !important;
  }

  .handsontable span.colHeader {
    display: inline-block;
    line-height: 1.1;
    font-family: Calibri;
    font-size: 15px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .handsontable .htFiltersActive .changeType {
    background: 0 0 !important
  }

  .handsontable .htFiltersActive .changeType:before {
    background-position: -84px -72px !important
  }

  .handsontable th.afterHiddenColumn::before {
    left: 1px;
    content: "" !important
  }

  #ImportGrid.handsontable td:nth-child(2) {
    text-align: center
  }

  #ReportGrid.handsontable td:nth-child(2) {
    text-align: center
  }

  #ReportGrid.handsontable {
    th {
      &:nth-child(2) {
        .changeType {
          &::after {
            content: "" !important;
            background: none !important;
            width: 0px;
            height: 0px;
            display: none;
            margin-top: 0px;
            margin-right: 0px !important;
          }
  
          &::before {
            background: none !important;
            width: 0px;
            height: 0px;
          }
        }
      }
    }
  }

  #ReportGrid.handsontable td:nth-child(2):hover {
    cursor: pointer
  }

  #ImportGrid.handsontable td:nth-child(2):hover {
    cursor: pointer
  }
  #statusList.handsontable td:nth-child(7) {
    text-align: center
  }
  #statusList.handsontable td:nth-child(9) {
    text-align: center
  }
  #statusList.handsontable td:nth-child(7):hover {
    cursor: pointer
  }
  #statusList.handsontable td:nth-child(9):hover {
    cursor: pointer
  }


  /*#contentManangement .handsontable {
    th {
      &:nth-child(2) {
        .changeType {
          &::after {
            content: "" !important;
            background: url(./assets/images/sprite.png) -64px -72px no-repeat !important;
            width: 20px;
            height: 20px;
            display: block;
            margin-top: 2px;
            margin-right: -10px !important;
          }
          &::before {
            background: none !important;
            width: 0px;
            height: 0px;
          }
        }
      }
    }
  }*/

  #contentManangement.handsontable .changeType:before {
    margin-top: -2px;
  }

  #contentManangement.handsontable span.colHeader {
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }

  #contentManangement.handsontable span.colHeader.columnSorting::before {
    right: 0px !important;
  }

  #adminGrid.handsontable td:last-child {
    text-align: center
  }

  #adminGrid.handsontable td:last-child:hover {
    cursor: pointer
  }

  #ProfileStructureGrid.handsontable {
    th {
      &:nth-last-child(1) {
        .changeType {
          &::after {
            content: "" !important;
            background: none !important;
            width: 0px;
            height: 0px;
            display: none;
            margin-top: 0px;
            margin-right: 0px !important;
          }

          &::before {
            background: none !important;
            width: 0px;
            height: 0px;
          }
        }
      }
    }
  }

  #ProfileStructureGrid.handsontable {
    th {
      &:nth-last-child(1) {
        .changeType {
          background: transparent;
          border-radius: 0px;
          border: 0px solid #bbb;
          margin: 0px;
          padding: 0px;
        }
      }
    }
  }

  .handsontable .changeType:before {
    content: "" !important;
    background: url(./assets/images/sprite.png) -64px -72px no-repeat;
    width: 20px;
    height: 20px;
    display: block;
    margin-top: 2px;
    margin-right: -10px !important
  }

  .handsontable .changeType {
    background: transparent;
    border-radius: 2px;
    border: 0px solid #bbb;
    color: #bbb;
    font-size: 9px;
    line-height: 9px;
    padding: 2px;
    margin: 3px 1px 0 5px;
    float: right;
  }

  #adminGrid.handsontable {
    th {
      &:last-child {
        .changeType {
          &::after {
            content: "" !important;
            background: none !important;
            width: 0px;
            height: 0px;
            display: none;
            margin-top: 0px;
            margin-right: 0px !important;
          }

          &::before {
            background: none !important;
            width: 0px;
            height: 0px;
          }
        }
      }
    }
  }

  #adminGrid.handsontable {
    th {
      &:last-child {
        .changeType {
          background: transparent;
          border-radius: 0px;
          border: 0px solid #bbb;
          margin: 0px;
          padding: 0px;
        }
      }
    }
  }

  .handsontable .changeType:hover {
    background: transparent;
    border-radius: 2px;
    border: 0px solid #bbb;
  }

  .handsontable .htFiltersActive .changeType {
    border: 0px;
    outline: 0px;
    color: #000 !important;
  }

}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

// ::-webkit-scrollbar-track {
// 	box-shadow   : inset 0 0 5px #9d9b9b;
// 	border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb {
// 	background   : #9d9b9b;
// 	border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb:hover {
// 	background: #9d9b9b;
// }

/* Rounded sliders */
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 30px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 1px;
    bottom: 1px;
    -webkit-transition: .4s;
    transition: .4s;
  }
}

input {
  &:checked {
    + {
      .slider {
        &:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }
      }
    }
  }

  &:focus {
    + {
      .slider {
        box-shadow: 0 0 1px #2196F3;
      }
    }
  }
}

.slider.round {
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
}

.StatusButton .form-label {
  display: block;
}

input:checked+.slider {
  background-color: #fff;
}

.slider {
  background-color: #fff;
  border: 1px solid #C9C9C9;
}

.slider.round:before {
  background-color: #C6C6C6;
}

input:checked+.slider:before {
  background-color: #43B02A;
  left: 0px;
}

.form-select:focus {
  box-shadow: none;
}

app-addedit-user.slideInactive {
  .AddEditUserContiner {
    transform: translate(371px, 0px);
    position: absolute;
  }

  position: inherit;
}

.BtnCancel,
.BtnSave {
  border: 1px solid #747474;
  border-radius: 6px;
  padding: 2px 10px;
  margin-left: 10px;
  min-width: 70px;
  font-family: Calibri;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;

}

.BtnSave {
  background-color: #43B02A;
  color: #fff;
  border-color: #43B02A;
}

.badge {
  padding: 2px 3px !important;
  border-radius: 50% !important;
  margin: 0px 5px 0px 0px;
  font-size: 50% !important;

  &:empty {
    display: inline-block !important;
  }
}

.badge-primary {
  &::before {
    content: "";
    color: #fff;
    position: relative;
    background: url('../src/assets/images/sprite.png') no-repeat !important;
    background-position: -205px -82px !important;
    width: 15px;
    height: 15px;
    display: block;
  }

  background-color: transparent !important;
  width: 14px;
  height: 14px;
  margin-right: 6px;
}

.badge-warning {
  background-color: transparent !important;
  width: 14px;
  height: 14px;
  margin-right: 6px;

  &::before {
    content: "";
    color: #fff;
    position: relative;
    background: url('../src/assets/images/sprite.png') no-repeat !important;
    background-position: -225px -81px !important;
    width: 15px;
    height: 15px;
    display: block;
  }
}

.badge-danger {
  &::before {
    content: "";
    color: #fff;
    position: relative;
    background: url('../src/assets/images/sprite.png') no-repeat !important;
    background-position: -224px -82px !important;
    width: 15px;
    height: 15px;
    display: block;
  }

  background-color: transparent !important;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.badge-label-kat {
  top: 2px;
  position: relative;
}

#ImportGrid.handsontable {
  table.htCore {
    tbody {
      td {
        &:first-of-type {
          color: #0091DA;
          font-family: Calibri;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 18px;
          text-decoration: underline;
        }
      }
    }
  }
}

#DataSnapshotGrid.handsontable {
  table.htCore {
    tbody {
      td {
        &:first-of-type {
          color: #0091DA;
          font-family: Calibri;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 18px;
          text-decoration: underline;
        }
      }
    }
  }
}

#LogicalModelParametersGrid.handsontable {
  table.htCore {
    tbody {
      td {
        &:nth-last-child(-1n+2) {
          color: #0091DA;
          font-family: Calibri;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 18px;
          /*text-decoration: underline;*/
        }
      }
    }
  }
}

#LogicalModelParametersGrid.handsontable {
  th {
    &:nth-last-child(-1n+2) {
      .changeType {
        &::after {
          content: "" !important;
          background: none !important;
          width: 0px;
          height: 0px;
          display: none;
          margin-top: 0px;
          margin-right: 0px !important;
        }

        &::before {
          background: none !important;
          width: 0px;
          height: 0px;
        }
      }
    }
  }
}

#LogicalModelParametersGrid.handsontable {
  th {
    &:nth-last-child(-1n+2) {
      .changeType {
        background: transparent;
        border-radius: 0px;
        border: 0px solid #bbb;
        margin: 0px;
        padding: 0px;
      }
    }
  }
}

#ReportGrid.handsontable {
  th {
    &:nth-child(6) {
      .changeType {
        &::after {
          content: "" !important;
          background: none !important;
          width: 0px;
          height: 0px;
          display: none;
          margin-top: 0px;
          margin-right: 0px !important;
        }

        // &::before {
        //   background: none !important;
        //   width: 0px;
        //   height: 0px;
        // }
      }
    }
  }
}

#ReportGrid.handsontable {
  th {
    &:nth-child(6) {
      .changeType {
        background: transparent;
        border-radius: 0px;
        border: 0px solid #bbb;
      }
    }
  }
}


#ImportGrid.handsontable {
  th {
    &:nth-child(7) {
      .changeType {
        &::after {
          content: "" !important;
          background: none !important;
          width: 0px;
          height: 0px;
          display: none;
          margin-top: 0px;
          margin-right: 0px !important;
        }

        &::before {
          background: none !important;
          width: 0px;
          height: 0px;
        }
      }
    }
  }
}

#ImportGrid.handsontable {
  th {
    &:nth-child(7) {
      .changeType {
        background: transparent;
        border-radius: 0px;
        border: 0px solid #bbb;
        margin: 0px;
        padding: 0px;
      }
    }
  }
}

#ImportGrid.handsontable {
  th {
    &:nth-child(2) {
      .changeType {
        &::after {
          content: "" !important;
          background: none !important;
          width: 0px;
          height: 0px;
          display: none;
          margin-top: 0px;
          margin-right: 0px !important;
        }

        &::before {
          background: none !important;
          width: 0px;
          height: 0px;
        }
      }
    }
  }
}

#ImportGrid.handsontable {
  th {
    &:nth-child(2) {
      .changeType {
        background: transparent;
        border-radius: 0px;
        border: 0px solid #bbb;
        margin: 0px;
        padding: 0px;
      }
    }
  }
}

#LogicalModelMetadataGrid.handsontable {
  table.htCore {
    tbody {
      td {
        &:nth-last-child(-3n+4) {
          color: #0091DA;
          font-family: Calibri;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 18px;
          /*text-decoration: underline;*/
        }
      }
    }
  }
}

#LogicalModelMetadataGrid.handsontable {
  th {
    &:nth-last-child(-3n+4) {
      .changeType {
        &::after {
          content: "" !important;
          background: none !important;
          width: 0px;
          height: 0px;
          display: none;
          margin-top: 0px;
          margin-right: 0px !important;
        }

        &::before {
          background: none !important;
          width: 0px;
          height: 0px;
        }
      }
    }
  }
}

#LogicalModelMetadataGrid.handsontable {
  th {
    &:nth-last-child(-3n+4) {
      .changeType {
        background: transparent;
        border-radius: 0px;
        border: 0px solid #bbb;
        margin: 0px;
        padding: 0px;
      }
    }
  }
}
#DataRollforwardGrid.handsontable {
  th {
    &:nth-of-type(2n+5) {
        &:not(:last-child) {
          .changeType {
            &::after {
              content: "" !important;
              background: none !important;
              width: 0px;
              height: 0px;
              display: none;
              margin-top: 0px;
              margin-right: 0px !important;
            }
    
            &::before {
              background: none !important;
              width: 0px;
              height: 0px;
            }
          }
        }
    }
  }
}

#DataRollforwardGrid.handsontable {
  th {
    &:nth-of-type(2n+5) {
      &:not(:last-child) {
        .changeType {
          background: transparent;
          border-radius: 0px;
          border: 0px solid #bbb;
          margin: 0px;
          padding: 0px;
        }
      }
    }
  }
}
#DataRollforwardGridWithoutActions.handsontable {
  th {
    &:nth-of-type(5) {
      .changeType {
        &::after {
          content: "" !important;
          background: none !important;
          width: 0px;
          height: 0px;
          display: none;
          margin-top: 0px;
          margin-right: 0px !important;
        }

        &::before {
          background: none !important;
          width: 0px;
          height: 0px;
        }
      }
    }
  }
}

#DataRollforwardGridWithoutActions.handsontable {
  th {
    &:nth-of-type(5) {
      .changeType {
        background: transparent;
        border-radius: 0px;
        border: 0px solid #bbb;
        margin: 0px;
        padding: 0px;
      }
    }
  }
}

#ModelsRollforwardGrid.handsontable {
  th {
    &:nth-child(7) {
      .changeType {
        &::after {
          content: "" !important;
          background: none !important;
          width: 0px;
          height: 0px;
          display: none;
          margin-top: 0px;
          margin-right: 0px !important;
        }

        &::before {
          background: none !important;
          width: 0px;
          height: 0px;
        }
      }
    }
  }
}

#ModelsRollforwardGrid.handsontable {
  th {
    &:nth-child(7) {
      .changeType {
        background: transparent;
        border-radius: 0px;
        border: 0px solid #bbb;
        margin: 0px;
        padding: 0px;
      }
    }
  }
}

.handsontable {
  table.htCore {
    tbody {
      td {
        color: #444444;
        font-family: Calibri;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }
}

hot-table .handsontable td:last-child i.infoBtn {
  font-size: 22px !important;
  padding-top: 8px;
}

hot-table .handsontable td:last-child i.actionBtn {
  font-size: 22px !important;
  padding-top: 8px;
  color: rgb(220, 53, 69);
  position: relative;
}

.form-select {
  display: block;
  width: 100%;
}

.kat-secondary-btn-red {
  background-color: #BC204B;
  padding: 10px 10px;
  min-width: 65px;
  color: #FFFFFF;
  font-family: Calibri;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 8px;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;

  &:hover {
    background-color: #bc204bdb;
    color: #fff;
  }
}

.proceed {
  margin-top: 30px;
  margin-left: 0px;
}

.kat-secondary-btn-color {
  background-color: #ffffff;
  padding: 10px 10px;
  min-width: 65px;
  color: #747474;
  font-family: Calibri;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 8px;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #747474;
}


.multiselect-dropdown {
  .dropdown-btn {
    border: 1px solid #D7D7D7 !important;
    border-radius: 6px !important;
    background-color: #FFFFFF !important;
    padding: 4px 10px 2px !important;
    height: 30px;
    font-size: 15px;

    .dropdown-multiselect__caret {
      &:before {
        background: url(./assets/images/sprite.png) -181px -44px no-repeat;
        border: 0px !important;
        width: 20px;
        height: 20px;
        content: "";
        top: -2px !important;
        position: absolute !important;
        left: 16px;
      }
    }

    .dropdown-multiselect--active {
      .dropdown-multiselect__caret {
        transform: rotateZ(180deg);
        top: -8px !important;
        right: -11px !important;
      }
    }

    .selected-item {
      border: 0px !important;
      background: none !important;
      color: #333333 !important;
      font-family: Calibri;
      font-size: 15px;
      letter-spacing: 0.44px;
      line-height: 22px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 150px !important;
      overflow: hidden;

      &:hover {
        box-shadow: none !important;
      }
    }
  }
}

.dropdown-list {
  width: 236px !important;
  margin-top: 3px !important;

  li {
    color: #333333;
    font-family: Calibri;
    font-size: 16px;
    letter-spacing: 0.44px;
    line-height: 22px;
    padding: 9px 10px !important;
  }
}

.multiselect-item-checkbox {
  input[type=checkbox] {
    display: none !important;
  }
}

.NoneCheckbox {
  .multiselect-item-checkbox {
    input[type=checkbox] {
      display: none !important;

      + {
        div {
          &:before {
            display: none;
          }

          &:after {
            display: none;
          }

          padding-left: 0em !important;
        }
      }
    }
  }
}

.multiselect-item-checkbox {
  input[type=checkbox] {
    + {
      div {
        &:before {
          border: 1px solid #005EB8 !important;
          border-radius: 4px;
          margin-top: -9px !important;
        }

        padding-left: 23px !important;

        &:after {
          margin-top: -5px !important;
          border-width: 0 0 2px 2px !important;
        }
      }
    }
  }
}

.mat-form-field-infix {
  width: 134px !important;
  border-top: 0px;
  padding: 0px !important;
  position: relative;
  top: -6px;
  font-size: 15px;
}

.mat-form-field-wrapper {
  padding: 0px !important;
}

.mat-form-field-label-wrapper {
  padding-top: 0px;
}

.mat-form-field-appearance-outline {
  .mat-form-field-outline {
    top: 0px !important;
  }
}

.mat-form-field-suffix {
  .mat-icon-button {
    width: 1.5em !important;
    height: 1.7em !important;
    top: -3px;
    right: -4px;
    color: #222;
  }
}

.mat-tab-label {
  background-color: lightgray;
  color: black;
  font-weight: bold;
  height: 35px !important;
  border-left: outset;
  border-right: inset;
}

.innerTab .mat-tab-label {
  background-color: white;
  color: black;
  font-weight: bold;
  border-left: none;
  border-right: none;
}


.mat-tab-label.mat-tab-disabled {
  display: contents;
}

.multiselect-dropdown {
  .dropdown-btn {
    font-size: 15px;
    font-size: 15px;
    color: #333333 !important;
    font-family: Calibri;
    letter-spacing: 0.44px;
    line-height: 19px !important;
  }
}

.roledropdown {
  .form-control {
    border: 0px;
    padding: 0px;
  }

  .dropdown-list {
    min-width: 310px;
  }

  .multiselect-dropdown {
    .dropdown-btn {
      .selected-item {
        display: inline-block;
        width: auto !important;
        background: #e1e1e1 !important;

        a {
          color: #333 !important;
          font-size: 13px;
          font-weight: bold;
        }
      }

      overflow: hidden;
    }
  }
}

.handsontable table.htCore tbody td:nth-child(2) {
  text-align: left;
}

#contentManangement .handsontable table.htCore tbody td:first-child {
  padding-left: 0px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #a2a2a2;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b9b9b9;
}

.CustomMenu a {
  color: #333333;
  font-family: Calibri;
  font-size: 15px;
  letter-spacing: 0.41px;
  line-height: 22px;
  padding: 5px 8px !important;
}


.mandatoryvalidation .multiselect-dropdown .dropdown-btn {
  border-color: red !important;
}

a {
  cursor: pointer;
}

.mandatoryvalidation .mat-form-field-appearance-outline .mat-form-field-outline-end,
.mandatoryvalidation .mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-color: red !important;
}

.filter-textbox input {
  padding-left: 0px !important;
}

.pagination {
  margin-bottom: 8px;
  margin-top: 8px;
}


.searchUserResultMain {
  font-size: 13px;
}



// .handsontable table.htCore tbody td:nth-child(2) {
//   border-left: 1px solid #CCC;
// }

// #adminGrid .handsontable table.htCore tbody td:nth-child(3 ) {
//   border-left: 1px solid #CCC;
// }

.handsontable table.htCore tbody td {
  line-height: 24px !important;
  vertical-align: top;
}

.cdk-overlay-container {
  z-index: 9999 !important;

  .mat-menu-panel {
    margin-top: 4px;
  }
}

.ng-select.ng-select-single {
  .ng-select-container {
    box-sizing: border-box;
    height: 30px !important;
    width: auto;
    border: 1px solid #D7D7D7;
    border-radius: 6px;
    background-color: #FFFFFF;
    min-height: 30px !important;
  }
}

.importModel,
.ckd-page,
.logical_model_management,
.importfromteamsite,.modalRollforwardPopup {
  .ng-dropdown-panel {
    min-width: 200px !important;
    width: -webkit-fill-available !important
  }
}

.ckd-page {
  width: 110% !important;
  .col.pr-0.ckd-id-col {
    min-width: 180px;
  }
  .ckd-ids-col {
    padding-right: 0px;
  }
  .col-2 {
    flex: 0 0 10% !important;
    max-width: 10% !important;
    padding: 0px;
  }
}

.modalforscreenzonetagging {
  .ng-dropdown-panel {
    min-width: 100px !important;
    width: -webkit-fill-available !important;
  }
}
 


.modalforFilter{
  .ng-dropdown-panel{
    width: -webkit-fill-available !important;
  }
}

.data-extract-main,
.saved_filter_modal,
.current-filter {
  .ng-dropdown-panel {
    min-width: 250px !important;
    width: -webkit-fill-available !important;
  }
}

.ng-dropdown-panel {
  min-width: auto;
  .ng-dropdown-panel-items {
    .ng-option {
      color: #333333;
      font-family: Calibri;
      font-size: 15px;
      letter-spacing: 0.41px;
      line-height: 20px;
      padding: 10px 10px !important;
    }
  }
}

.ng-select.ng-select-opened.ng-select-bottom {
  >.ng-select-container {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

.ng-dropdown-panel.ng-select-bottom {
  border-radius: 4px;
  border: 1px solid #D7D7D7;
  margin-top: 2px;
  box-shadow: 0 2px 4px 0 #9b9b9b;
  width: fit-content;
}


.ng-value-container {
  color: #333333;
  font-family: Calibri;
  font-size: 15px;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  background: url(./assets/images/sprite.png) -181px -44px no-repeat;
  border: 0px !important;
  width: 20px !important;
  height: 20px !important;
  content: "";
  top: 0px !important;
  position: absolute !important;
  left: 0px;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  transform: rotate(180deg);
  top: 4px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 200px !important;
}

.ng-select .ng-select-container {
  box-sizing: border-box;
  height: 30px !important;
  width: auto;
  border: 1px solid #D7D7D7;
  border-radius: 6px;
  background-color: #FFFFFF;
  min-height: 30px !important;
}

ng-dropdown-panel {
  input[type=checkbox] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    display: none;

    &:checked {
      + {
        div {
          &:before {
            -webkit-animation: .2s ease-in borderscale;
            animation: .2s ease-in borderscale;
            background: #337ab7;
          }

          &:after {
            content: "";
            transition: transform .2s ease-out;
            transform: rotate(-45deg) scale(1);
          }
        }
      }
    }
  }
}

ng-dropdown-panel {
  input[type=checkbox] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    display: none;

    &:checked {
      + {
        #t1 {
          &:before {
            -webkit-animation: .2s ease-in borderscale;
            animation: .2s ease-in borderscale;
            background: #a0a1a2;
          }

          &:after {
            content: "";
            transition: transform .2s ease-out;
            transform: rotate(-45deg) scale(1);
          }
        }
      }
    }
  }
}

.Checkboxstyle {
  &:before {
    box-sizing: content-box;
    content: "";
    color: #337ab7;
    position: absolute;
    top: 50%;
    left: 0;
    width: 14px;
    height: 14px;
    margin-top: -9px;
    border: 2px solid #337ab7;
    text-align: center;
    transition: .4s;
    border: 1px solid #005EB8 !important;
    border-radius: 4px;
    margin-top: -9px !important;
  }

  &:after {
    box-sizing: content-box;
    content: "";
    position: absolute;
    transform: scale(0);
    transform-origin: 50%;
    transition: transform .2s ease-out;
    background-color: transparent;
    top: 50%;
    left: 4px;
    width: 8px;
    height: 3px;
    margin-top: -4px;
    border-style: solid;
    border-color: #fff;
    border-width: 0 0 3px 3px;
    -o-border-image: none;
    border-image: none;
    transform: rotate(-45deg) scale(0);
    box-sizing: content-box;
    content: "";
    position: absolute;
    transform: scale(0);
    transform-origin: 50%;
    transition: transform .2s ease-out;
    background-color: transparent;
    top: 50%;
    left: 4px;
    width: 8px;
    height: 3px;
    margin-top: -4px;
    border-style: solid;
    border-color: #fff;
    border-width: 0 0 3px 3px;
    -o-border-image: none;
    border-image: none;
    transform: rotate(-45deg) scale(0);
    margin-top: -5px !important;
    border-width: 0 0 2px 2px !important;
  }

  position: relative;
  padding-left: 22px;
}

.serachSeaction {
  input.form-control {
    height: 30px;
    color: #333333;
    font-family: Calibri;
    font-size: 15px;
    border: 1px solid #D7D7D7;
    border-radius: 6px !important;
  }

  button.btn.btn-outline-secondary {
    padding: 5px 15px;
    margin-left: 13px;
    color: #ffffff;
    font-family: Calibri;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    background: #005eb8;
    border-radius: 4px;
  }

  button.btn.btn-outline-secondary:active {
    color: white !important;
    background: #005eb8;
 }
}

.entryViewSerach .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 200px !important;
  overflow-y: auto;
}

.ng-select .ng-arrow-wrapper {
  height: 25px !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Calibri;
}

.badge-danger.fa {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  margin-left: 5px;
  background-color: #dc3545 !important;

  &::before {
    background: none !important;
    width: auto;
    height: auto;
  }
}

.collapsibleIndicator {
  position: relative;
  transform: translate(0%, -50%);
  right: 0px;
  line-height: 10px;
  color: #222;
  border-radius: 10px;
  font-size: 10px;
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
  top: 4px !important;
}

.collapsibleIndicator {
  border: 0px;
  box-shadow: none;
  background: #fff;
  color: #fff;
  text-align: center;
  width: 13px;
  font-size: 12px;
  height: 13px;
  font-weight: bold;

}

.collapsibleIndicator::before {
  font-family: FontAwesome;
  content: "\f104" !important;
  color: #222222;
  font-size: 18px;
  position: relative;
  top: 4px;
}

.collapsibleIndicator.collapsed::before {
  font-family: FontAwesome;
  content: "\f105" !important;
  color: #222222;
  font-size: 18px;
  position: relative;
  top: 4px;
}

.handsontable th div.ht_nestingButton.ht_nestingCollapse::after {
  content: "\f0d7";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

#contentManangement .handsontable td div.ht_nestingButton.ht_nestingCollapse::after {
  content: "\f0d7";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  float: left;
  margin-right: 5px;
}

// content management 
.iconcontainer {
  width: calc(100% - 40px);
  height: 30px;
  position: relative;
  top: 7px;
  left: 7px;
  float: left;

  span.notificationicons {
    position: absolute;
    right: 0px;
    font-size: 10px;

    &::after {
      content: '';
      width: 5px;
      height: 5px;
      position: absolute;
      right: 0px;
      border: 1px solid #FFFFFF;
      background-color: #BC204B;
      border-radius: 50%;
    }
  }

  span.externalcheckin {
    position: absolute;
    top: 25px;
    left: 0px;
    display: inline-block;
    text-align: center;
    background-color: #333;
    color: #fff;
    padding: 3px;
  }

  span.externalcheckin-disabled {
    background-color: #a2a2a2;
  }
}

.dropdownContainer {
  padding: 10px;
}

.dropdownContainer .form-control {
  border: 1px solid #D7D7D7;
  border-radius: 6px;
  background-color: #FFFFFF;
  line-height: 1;
  height: 30px;
  width: calc(100% - 40px);
  padding: 0px 6px;
}

.sliderroundswitch .switch {
  margin-top: 0px !important;
}

.sliderroundswitch {
  text-align: center;
}

.text-center {
  text-align: center;
}

.textareaContainer textarea {
  width: calc(100% - 40px);
  height: 30px;
  border: 1px solid #D7D7D7;
  border-radius: 6px;
  background-color: #FFFFFF;
  resize: none;
  padding: 4px;
}

.textareaContainer {
  padding: 8px;
}


// content management 
#contentManangement .handsontable td div.ht_nestingButton {
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 9px;
  cursor: pointer;
}

.handsontable th div.ht_nestingButton.ht_nestingExpand::after {
  content: "\f0da";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

#contentManangement .handsontable td div.ht_nestingButton.ht_nestingExpand::after {
  content: "\f0da";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  float: left;
  margin-right: 5px;
}

hot-table#contentManangement .handsontable td:last-child {
  text-align: left;
}

.LifeCycles .ng-placeholder+div {
  display: block !important;
}


.LifeCycles .ng-value-container .ng-value {
  display: none;
}

.dashboard_cover .col-lg-2 {
  flex: 0 0 13.5%;
  max-width: 13.5%;

  .card {
    min-height: 170px;

    h3 {
      font-size: 24px !important;
    }
  }
}

.LifeCycles.wfName .ng-value .ng-value-label {
  width: 30px !important;
}

.LifeCycles.colgrp .ng-value .ng-value-label {
  width: 40px !important;
}

.LifeCycles .ng-value .ng-value-label {
  width: 50px !important;
  line-height: normal !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: inline-block !important;
  font-size: 12px !important;
  top: 4px !important;
  position: relative !important;
}

.LifeCycles .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis !important;
  font-size: 14px;
}

.LifeCycles .ng-value-container .ng-placeholder {
  font-size: 14px;
}


.LifeCycles .ng-placeholder,
.entryViewSerach .ng-placeholder {
  font-size: 14px !important;
  width: 140px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#ImportGrid,
#adminGrid,

#DataSnapshotGrid,
#ImportFromTeamsiteGrid,
#LogicalModelParametersGrid,
#LogicalModelMetadataGrid,
#ProfileDetailsGrid,
#ProfileStructureGrid {
  height: calc(100vh - 220px) !important;
  overflow: auto !important;
}

#contentManangement{
  height: calc(100vh - 220px) !important;
  overflow: hidden !important;
}

#ReportGrid {
  height: calc(100vh - 160px) !important;
  overflow: auto !important;
}

#DataRollforwardGrid {
  height: calc(100vh - 168px) !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

#ModelsRollforwardGrid {
  height: calc(100vh - 168px) !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

@media only screen and (max-width: 1366px) {

  .LifeCycles .ng-placeholder,
  .entryViewSerach .ng-placeholder {
    font-size: 12px !important;
    width: 105px !important;
  }

  .serachSeaction input::-webkit-input-placeholder {
    font-size: 13px !important;
  }
}

.downloadBtn {
  color: #007bff !important;
  text-decoration: none !important;
  background-color: transparent;
  cursor: pointer;
}

// footerChanges

.dashboard_cover {
  height: calc(100vh - 162px);
  min-height: calc(100vh - 162px);
}

.MainContainer {
  min-height: calc(100vh - 162px);
}

.UserGrid .GridHeader {
  border-bottom: 0px !important;
}

.ReportGrid .GridHeader {
  border-bottom: 0px !important;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 !important;
}

.show {
  visibility: visible;
}

.hide {
  visibility: hidden;
}

#OverlayContentManagementGrid {
  height: calc(100vh - 160px) !important;
  position: absolute;
  top: 90px;
  left: 0px;
  width: 100%;
  z-index: 9998;
  background: white;
}

.date-in-grid {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 150px;
  padding: 3px;
}

.handsontable .form-control {
  height: calc(1.2em + 0.75rem + 2px) !important;
  margin-top: 8px !important;
}

.handsontable select.form-control[multiple] {
  height: auto !important;
}

div.checkout-box-border {
  position: relative;
  padding-left: 5px;
  border-right: 1px solid #CCC;
  width: 30px;
  padding-top: 8px;
  height: 100px;
  float: left;
}

div.checkout-box-border.chunk {
  border-left: .1px solid #869edf;
}

.ckd-readonly {
  max-height: 75px !important;
  overflow: auto;
}

input[type=checkbox] {
  cursor: pointer;
}

input[type="checkbox"]:disabled {
  cursor: auto;
}

.text-resize {
  resize: vertical !important;
  max-height: 60px !important;
  min-height: 25px !important;
}

.handsontable textarea {
  resize: none !important;
  max-height: 82px !important;
  min-height: 82px !important;
}

.handsontable .deprecated-row {}

.checkout-div {
  margin-left: 10px;
}

.add-new-cvv {
  margin-top: 5px;
}

.add-new-cvv-input {
  display: initial;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 75%;
}

#ImportFromTeamsiteGrid.handsontable {
  th {
    &:nth-last-child(1) {
      .changeType {
        &::after {
          content: "" !important;
          background: none !important;
          width: 0px;
          height: 0px;
          display: none;
          margin-top: 0px;
          margin-right: 0px !important;
        }

        &::before {
          background: none !important;
          width: 0px;
          height: 0px;
        }
      }
    }
  }
}

#ImportFromTeamsiteGrid.handsontable {
  th {
    &:nth-last-child(1) {
      .changeType {
        background: transparent;
        border-radius: 0px;
        border: 0px solid #bbb;
        margin: 0px;
        padding: 0px;
      }
    }
  }
}

//below styles belongs to Logical Model Management Tab 
.logical_model_management .mat-tab-header .mat-tab-label-container .mat-ink-bar._mat-animation-noopable {

  background-color: #005eb8;
}

.logical_model_management .mat-tab-label {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 0px 2px;
  border-left: outset transparent;
  border-right: inset transparent;
}

//code for jw-modal on checked out user filter
.jw_checkout_User_Filter {
  .ng-dropdown-panel {
    box-shadow: none;
    border: none;
  }

  .jw-modal-background {
    opacity: unset;
    background-color: unset;
  }

  .ng-dropdown-panel-items {
    height: 115px !important;
  }

  .ng-select .ng-arrow-wrapper {
    display: none;
  }
}

//Style for CLCM Export Modal
.modalforclcmExport {
  .ng-select-container {
		.ng-placeholder {
      color: #c6c6c6 !important;
			font-size: 15px;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 77%;
			overflow: hidden;
      font-style: italic;
		}
	}
}

//ScreenZone Single update tagging
#CurrentSelectionsGrid.handsontable {
  th {
    &:nth-child(1) {
      .changeType {
        &::after {
          content: "" !important;
          background: none !important;
          width: 0px;
          height: 0px;
          display: none;
          margin-top: 0px;
          margin-right: 0px !important;
        }

        &::before {
          background: none !important;
          width: 0px;
          height: 0px;
        }
      }
    }
  }
}

#CurrentSelectionsGrid.handsontable {
  th {
    &:nth-child(1) {
      .changeType {
        background: transparent;
        border-radius: 0px;
        border: 0px solid #bbb;
        margin: 0px;
        padding: 0px;
      }
    }
  }
}

#PreviousSelectionsGrid.handsontable {
  th {
    &:nth-child(1) {
      .changeType {
        &::after {
          content: "" !important;
          background: none !important;
          width: 0px;
          height: 0px;
          display: none;
          margin-top: 0px;
          margin-right: 0px !important;
        }

        &::before {
          background: none !important;
          width: 0px;
          height: 0px;
        }
      }
    }
  }
}

#PreviousSelectionsGrid.handsontable {
  th {
    &:nth-child(1) {
      .changeType {
        background: transparent;
        border-radius: 0px;
        border: 0px solid #bbb;
        margin: 0px;
        padding: 0px;
      }
    }
  }
}

.buttonContainer .mat-mdc-menu-trigger span.mdc-button__label{color: #fff;}
.versionYeardropdown .ng-dropdown-panel.ng-select-bottom {
  min-width: 84px !important;
}
.logical_model_management .mat-mdc-tab-body.mat-mdc-tab-body-active, .logical_model_management .mat-mdc-tab-body-content{overflow: hidden;}

.ml-2
{
  margin-left: 10px;  
}

.float-right {
  float: right;
}